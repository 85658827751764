@import '../../__assets/styles/variables.scss';

.component__profile-photo {
  padding-left: 5px;
  padding-right: 5px;

  &__photo-wrapper {
    width: calc(#{$photoSmall} + 8px);
    height: calc(#{$photoSmall} + 8px);
    background-color: $actionBlue;
    border-radius: calc((#{$photoSmall} + 8px) / 2);
    position: relative;
    left: -4px;
    top: -4px;
    z-index: 0;
    
    &::before {
      content: '';
      width: calc(#{$photoSmall} + 4px);
      height: calc(#{$photoSmall} + 4px);
      background-color: $white;
      border-radius: calc((#{$photoSmall} + 4px) / 2);
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
    }
    &::after {
      content: attr(data-initials);
      color: $pageGray;
      font-size: px-to-rem(22);
      font-weight: 700;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
    }
    & > div {
      width: $photoSmall;
      height: $photoSmall;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: calc(#{$photoSmall} / 2);
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);
    }
    &.large {
      width: calc(#{$photoLarge} + 8px);
      height: calc(#{$photoLarge} + 8px);
      border-radius: calc((#{$photoLarge} + 8px) / 2);

      &::before {
        width: calc(#{$photoLarge} + 4px);
        height: calc(#{$photoLarge} + 4px);
        border-radius: calc((#{$photoLarge} + 4px) / 2);
      }
      &::after {
        font-size: px-to-rem(36);
      }
      & > div {
        width: $photoLarge;
        height: $photoLarge;
        border-radius: calc(#{$photoLarge} / 2);
      }
    }
  }
}