@import '../../__assets/styles/variables.scss';

$miscHeaderHeight: 32px;
$miscHeaderInviteBlue: #003255;
$miscHeaderCountGray: #424959;
$chatMessageHeight: 90px;

.page__watchparty {
  height: 100%;
  display: flex;
  flex-direction: column;

  [contenteditable="true"] {
    &:empty:before {
      content: attr(placeholder);
      pointer-events: none;
      opacity: 0.5;
    }
    &:focus {
      outline: 0;
    }
  }
  &__misc-header {
    min-height: $miscHeaderHeight;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid #bdbdbd;
  }
  &__hbutton {
    padding: 0;
    width: 38px;
    background-color: transparent;
    border: none;

    img {
      height: 22px;
    }
    &--app-menu {
      img {
        height: 25px;
      }
    }
    &--fan-count {
      font-size: px-to-rem(16);
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;
        max-height: 18px;
      }
    }
  }
  &__chat-container {
    padding-bottom: 20px;
    height: 0; // solution for inner element scroll
    flex: 1;
    overflow: auto;
  }
  &__load-previous {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;

    button {
      color: $actionBlue;
      background-color: transparent;
      border: none;
    }
  }
  .flagged-message {
    margin: 0;
    padding: 15px;
    border-bottom: 1px solid rgba(213, 213, 213, 0.3);
    font-size: px-to-rem(14);
    font-style: italic;
    line-height: 1.3125;
    
    button {
      padding: 0;
      border: none;
      background-color: transparent;
      text-decoration: underline;
      font-style: italic;
    }
  }
  &__chat-message-box {
    padding: 10px;
    min-height: 60px;
    border-top: 1px solid #bdbdbd;

    .typing-area {
      margin-bottom: 10px;

      .message-input,
      .gif-input {
        padding-top: 5px;
        padding-bottom: 5px;
        width: 100%;
        font-size: px-to-rem(16);
        background-color: $white;
        color: #232324;
        resize: none;
        // border: none;
      }
    }
    .action-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      .gif-button {
        padding: 5px;
        font-size: px-to-rem(16);
        color: $white;
        background-color: $miscHeaderCountGray;
        border-radius: 7px;
      }
      .send-button img {
        height: 32px;
        vertical-align: bottom;
      }
    }
    .gif-input {
      text-align: center;
      
      .selected-gif {
        display: inline-block;
        position: relative;
  
        button {
          padding: 4px 7px;
          border: none;
          border-radius: 8px;
          box-shadow: 0 0 3px 0px #232324;
          position: absolute;
          top: -5px;
          right: -10px;
        }
      }
    }
  }
  &__gif-drawer {
    padding: 10px 15px 20px;
    border-top: 1px solid #bdbdbd;

    .gif-input {
      margin-bottom: 12px;
      padding: 7px 12px;
      font-size: px-to-rem(16);
      background-color: rgba(#d4d4d4, 0.35);
      border-radius: 15px;
    }
    .gif-chooser {
      height: 200px;
      overflow-y: auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 8px;
      row-gap: 8px;

      .gif-image {
        height: 90px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  &__more-menu {
    position: fixed;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.6);

    .options {
      width: 100%;
      min-height: 100px;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 25px 30px;
      background-color: $white;
      border: 1px solid #707070;
      
      button {
        padding: 0;
        width: 100%;
        text-align: left;
        border: none;
        background-color: transparent;
        font-size: px-to-rem(16);
  
        img {
          width: 20px;
          margin-right: 10px;
          vertical-align: text-bottom;
        }
      }
    }
  }
  &__members {
    overflow: auto;

    .member {
      padding: 15px;
      display: flex;
      
      &[data-is-last="false"] {
        border-bottom: 1px solid rgba(213, 213, 213, 0.3);
      }
      .user-name {
        margin: 0;
        margin-top: 12px;
      }
      .linkedin-link {
        display: block;
        margin-top: 10px;
        font-size: px-to-rem(12);
        color: inherit;
        text-decoration: none;
        text-transform: uppercase;
        opacity: 0.75;

        img {
          width: 18px;
          position: relative;
          top: 4px;
        }
      }
    }
  }
  &__app-menu {
    max-width: 225px;
    padding-top: 40px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100%;
    right: 100%;
    background-color: #041e42;
    box-shadow: -8px 0 20px 0px $pageGray;
    transition: all 0.25s ease;
    
    &.is-open {
      left: 0;
      right: 50%;
    }
    &.heading-pad {
      top: $headerHeight;
    }
    .close-button {
      padding: 0;
      background-color: transparent;
      border: none;
      position: absolute;
      top: 8px;
      right: 10px;

      img {
        width: 28px;
      }
    }
    ul {
      margin: 0;
      padding: 0;

      a {
        color: $white;
        text-decoration: none;
        font-size: px-to-rem(18);
        
        &:not(:first-of-type) li {
          margin-top: 10px;
        }
      }
      li {
        padding: 5px 30px;
      }
    }
  }
  .user-name {
    font-weight: 700;

    span::before {
      content: '\00B7';
      padding-left: 5px;
      padding-right: 5px;
      font-size: px-to-rem(30);
      position: relative;
      top: 4px;
      line-height: 0;
    }
  }
}