@import '../../__assets/styles/variables.scss';

.page__settings {
  display: flex;
  flex-direction: column;
  height: 100%;
  
  &__page-info {
    margin-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: $actionBlue;
      text-decoration: none;
    }
  }
  &__title {
    text-align: center;

    h1 {
      font-size: px-to-rem(28);
      color: $actionBlue;
    }
  }
  &__form {
    margin-top: 10px;
    margin-bottom: 30px;
    padding-left: 16px;
    padding-right: 16px;
  }
}