@import '../../__assets/styles/variables.scss';

.component__header-app {
  padding: 15px;
  min-height: $headerHeight;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #041e42;
  color: $headerWhite;
  font-size: px-to-rem(24);
  font-weight: 700;
  text-align: center;
  box-sizing: border-box;
}