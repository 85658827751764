@import '../../__assets/styles/variables.scss';

.page__login {
  display: flex;
  flex-direction: column;
  height: 100%;
  
  &__page-info {
    margin-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
  &__title {
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;

    h1 {
      font-size: px-to-rem(28);
      color: $actionBlue;
    }
    p {
      margin-top: 0;
      line-height: 1.25;
    }
  }
  &__form {
    margin-top: 10px;
    margin-bottom: 30px;
    padding-left: 16px;
    padding-right: 16px;
  }
}