@import '../__assets/styles/variables.scss';

@font-face {
  font-family: 'Helvetica';
  src: url('../__assets/fonts/helvetica-light.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica';
  src: url('../__assets/fonts/Helvetica.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica';
  src: url('../__assets/fonts/Helvetica-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'Helvetica', sans-serif;
  
  * {
    box-sizing: border-box;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }
  .page-container {
    padding-bottom: 20px;
    height: 0; // solution for inner element scroll
    flex: 1;
    overflow: auto;
  }
  form {
    .form-row {
      margin-top: 14px;
      display: flex;
      flex-direction: column;
      position: relative;

      label {
        margin-bottom: 5px;
        font-size: px-to-rem(18);
      }
      input[type="text"], 
      input[type="email"], 
      input[type="number"],
      input[type="url"],
      input[type="password"] {
        padding: 7px 10px;
        min-height: 40px;
        background-color: $white;
        border: 1px solid #f0f0f0;
        border-radius: 4px;
        font-size: px-to-rem(15);
      }
      input[type="file"] {
        position: absolute;
        left: -99999px;
      }
      .show-password-button {
        padding: 0;
        margin: 0;
        font-size: px-to-rem(13);
        font-weight: 700;
        text-transform: uppercase;
        color: $actionBlue;
        background-color: transparent;
        border: none;
        position: absolute;
        top: 5px;
        right: 0;
      }
      input[type="submit"] {
        margin-top: 16px;
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: $actionBlue;
        color: $white;
        border: none;
        border-radius: 10px;
        font-size: px-to-rem(16);
        font-family: 'Helvetica', sans-serif;
        font-weight: 700;
        -webkit-appearance: none;

        &[disabled] {
          background-color: lighten($pageGray, 20%);
          opacity: 0.75;
        }
      }
    }
  }
  .or-divider {
    padding-left: 16px;
    padding-right: 16px;
    margin: 12px auto 0;
    width: calc(100% - 32px);
    height: 36px;
    position: relative;
    line-height: 36px;
    text-align: center;
    
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 130px;
      height: 1px;
      top: 17px;
      background-color: #aaa;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
  .social-logins {
    margin-top: 20px;
    padding-left: 16px;
    padding-right: 16px;

    button {
      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
      display: block;
      background-color: #00538c;
      color: $white;
      box-shadow: 0 3px 20px rgba(0, 83, 140, 0.24);
      border: none;
      border-radius: 10px;
      font-size: px-to-rem(18);
      font-family: 'Helvetica', sans-serif;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 30px;
        margin-right: 12px;
      }
    }
  }
  .app-toast {
    & > div {
      transition: all .3s ease 0s !important;
      
      & > div {
        animation: slide-in .3s ease !important;
      }
    }
  }
  @media screen and (max-width: 767px) {
		position: fixed;
		top: 0;
		bottom: 0;
    left: 0;
    right: 0;

    #root, .app-wrapper {
      width: 100%;
			height: 100%;
    }
	}
}
@keyframes slide-in {
  0% { transform: translateY(-200%) }
  100% { transform: translateY(0) }
}
@keyframes slide-out {
  0% { transform: translateY(0) }
  100% { transform: translateY(-200%) }
}