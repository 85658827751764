@import '../../__assets/styles/variables.scss';

.component__login-popover {
  width: 100vw;
  padding: 10px 25px 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  box-shadow: 0 0 28px rgba(0, 83, 140, 0.24);

  &__title {
    margin-bottom: 20px;
    text-align: center;
    font-size: px-to-rem(20);
    color: #00538c;
  }
  &__blurb {
    font-size: px-to-rem(15);
    text-align: center;
    line-height: 1.25;
  }
  &__auth-options {
    a {
      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
      display: block;
      background-color: $actionBlue;
      color: $white;
      border: none;
      border-radius: 10px;
      font-family: 'Helvetica', sans-serif;
      font-weight: 700;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;

      &:last-of-type {
        background-color: transparent;
        color: $actionBlue;
        box-shadow: none;
        border: 1px solid $actionBlue;
        margin-top: 12px;
      }
    }
  }
}