@import '../../__assets/styles/variables.scss';

.component__watchparty-chat-message {
  padding: 15px;
  display: flex;
  
  &[data-is-last="false"] {
    border-bottom: 1px solid rgba(213, 213, 213, 0.3);
  }
  &__message-content {
    padding-left: 5px;
    padding-right: 15px;
    flex: 1;

    .content {
      p {
        margin: 0;
      }
      .user-message {
        margin-top: 5px;
      }
      .message-image {
        width: 100%;
        height: 150px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .sent {
        font-size: 0.75em;
        text-align: right;
        opacity: 0.5;
      }
    }
    .actions {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        height: 20px;
      }
      button {
        background-color: transparent;
        border: none;
      }
      .primary {
        flex: 1;
        display: grid;
        align-items: center;
        justify-items: start;
        grid-template-columns: 0.25fr 0.25fr;
        
        button {
          padding: 0;
          font-size: px-to-rem(16);

          img {
            vertical-align: text-bottom;
            margin-right: 5px;
          }
        }
      }
    }
  }
}