$white: #fff;
$pageGray: #424950;
$actionBlue: #03a7ed;
$headerWhite: #efefef;

$photoSmall: 46px;
$photoLarge: 100px;
$headerHeight: 80px;

$base-font-size: 16;

@function px-to-rem($px) {
  @return ($px / $base-font-size) + rem;
}