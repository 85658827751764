@import '../../__assets/styles/variables.scss';

.page__register-profile {
  display: flex;
  flex-direction: column;
  height: 100%;
  
  &__page-info {
    margin-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: $actionBlue;
      text-decoration: none;
    }
  }
  &__title {
    text-align: center;

    h1 {
      font-size: px-to-rem(28);
      color: $actionBlue;
    }
  }
  &__form {
    margin-top: 10px;
    padding-left: 16px;
    padding-right: 16px;

    .form-row {
      .go-to-party {
        display: block;
        margin-top: 16px;
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: $actionBlue;
        color: $white;
        border: none;
        border-radius: 10px;
        font-size: px-to-rem(16);
        font-family: 'Helvetica', sans-serif;
        font-weight: 700;
        text-align: center;
        text-decoration: none;
      }
    }
  }
  &__change-photo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-size: px-to-rem(16);
      font-weight: 700;
      color: $actionBlue;
    }
    input {
      position: absolute;
      left: -9999px;
    }
  }
}