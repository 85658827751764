@import '../../__assets/styles/variables.scss';


.component__page-nav {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    color: $pageGray;
    font-size: px-to-rem(14);
  }
  &.single {
    a:first-of-type {
      margin-right: 0;
    }
  }
  &.login {
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;
  }
  &:not(.login):not(.single) a:first-of-type {
    margin-right: 50px;
  }
}