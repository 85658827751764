@import '../../__assets/styles/variables.scss';

.component__header-auth {
  height: 100px;
  background-color: $pageGray;
  display: flex;
  justify-content: center;
  align-items: center;

  .party-logo {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  img {
    max-height: 60px;
  }
}